
import {mapActions, mapGetters} from 'vuex'
import passwordForm from '@/components/form/passwordForm'

export default {
  name: 'Login',
  components: { passwordForm },
  data () {
    return {
      errorMessage: '',
      successMessage: '',
      form: {
        username: '',
        password: ''
      }
    }
  },
  computed: {
    ...mapGetters({
      serviceToConnect: 'serviceToConnect',
      gtmUserData: 'gtmUserData',
      loginPageMessage: 'loginPageMessage'
    }),
    showDoubleAuthentificationMessage  () {
      const showDoubleAuthentificationMessage = this.$cookies.get('showDoubleAuthentificationMessage')
      if (showDoubleAuthentificationMessage !== undefined) {
        return showDoubleAuthentificationMessage
      }
      return false
    }
  },
  beforeDestroy () {
    this.clearDoubleAuthentificationMessage()
    this.clearLoginPageMessage()
  },
  methods: {
    ...mapActions({
      clearLoginPageMessage: 'clearLoginPageMessage'
    }),
    async userLogin () {
      try {
        const form = new FormData()
        form.append('username', this.form.username)
        form.append('password', this.form.password)
        await this.$auth.loginWith('local', { data: form }).then(async () => {
          await this.$backOffice.getActiveSubscriptionsInfo().then(async (activeSubscriptionsInfo) => {
            await this.$backOffice.getActiveDemosInfo().then((activeDemosInfo) => {
              if ((activeSubscriptionsInfo.length + activeDemosInfo.length) === 0) {
                this.$auth.logout()
                this.errorMessage = this.$i18n.t('login.noTrialOrContract')
              } else {
                this.$gtm.push({
                  event: 'login',
                  login_type: 'client',
                  user_data: this.gtmUserData
                })
                if (this.serviceToConnect.code !== undefined) {
                  let path = '/connect'
                  path += (this.serviceToConnect.code) ? '/' + this.serviceToConnect.code : '/'
                  path += (this.serviceToConnect.newsPath) ? '?path=' + this.serviceToConnect.newsPath : ''
                  this.$router.push({ path })
                } else {
                  this.$router.push({ path: '/' })
                }
              }
            })
          })
        })
      } catch (err) {
        this.errorMessage = this.$i18n.t('login.invalidCredential')
      }
    },
    clearDoubleAuthentificationMessage () {
      this.$cookies.remove('showDoubleAuthentificationMessage')
    },
    handleForgotPasswordClick () {
      this.$bvModal.show('password-modal')
    },
    displaySuccessForgotPasswordRequested () {
      this.successMessage = this.$i18n.t('login.successForgotPasswordRequest')
    }
  }
}
